import { AccountCreate, ResponseCreate, RoleCreate, UserReview } from ".";
import { UserRequestProvider } from "../../store/contexts";
import { useUserRequest } from "../../store/hooks";

const Switcher = ({ children }) => {
  const { stepIndex } = useUserRequest();

  return children?.map((route, index) => {
    return (
      <section
        key={`route-${index}`}
        className={stepIndex === index ? "h-full block" : "hidden"}
      >
        {route}
      </section>
    );
  });
};

const NewUserForm = ({ onClose, onSuccess }) => {
  return (
    <UserRequestProvider closeHandler={onClose}>
      <Switcher>
        <AccountCreate />
        <RoleCreate />
        <UserReview />
        <ResponseCreate onSuccess={onSuccess} />
      </Switcher>
    </UserRequestProvider>
  );
};

export default NewUserForm;
