import { useCallback, useMemo, useState } from "react";
import { FaRegCircleCheck as CheckIcon } from "react-icons/fa6";
import { MdCloudOff as NoIcon } from "react-icons/md";
import { Checkbox, Table } from "../../components";

export const GroupList = ({
  groups,
  updateGroups,
  numChannels,
  selectedCount,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleChange = useCallback(
    (id, checked) => {
      if (id === "chk-all") {
        setSelectAll((prev) => !prev);
        updateGroups((prevItems) =>
          prevItems.map((item) =>
            item.count === 0 ? item : { ...item, checked }
          )
        );
      } else
        updateGroups((prevItems) =>
          prevItems.map((item, index) =>
            index === id && item.count !== 0 ? { ...item, checked } : item
          )
        );
    },
    [updateGroups]
  );

  const columns = useMemo(() => {
    return [
      {
        key: "Select",
        render: (g, i) => {
          const checkbox =
            g.name === undefined ? (
              <Checkbox
                key="chk-all"
                id="chk-all"
                checked={selectAll}
                onChange={({ target }) =>
                  handleChange("chk-all", target.checked)
                }
                sx={{ marginLeft: 2 }}
              />
            ) : g.count ? (
              <Checkbox
                key={g.id}
                id={g.id}
                checked={g.checked}
                onChange={({ target }) => handleChange(i, target.checked)}
              />
            ) : g.imported === 0 ? (
              <NoIcon color="brown" size={18} />
            ) : null;
          return <section className="pl-2">{checkbox}</section>;
        },
      },
      {
        label: "Channel Groups",
        render: (g) => <p className="py-1">{g.name}</p>,
      },
      {
        label: "Imported",
        render: ({ count, imported }) => `${imported}/${count}`,
        align: "text-center",
      },
      {
        key: "Status",
        width: 52,
        render: ({ count, imported }) => {
          if (count > 0) {
            return (
              count === imported && (
                <CheckIcon
                  className="flex space-x-2 text-secondary"
                  size={19}
                />
              )
            );
          }
        },
      },
    ];
  }, [selectAll, handleChange]);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <p>
          {`${
            groups.length
          } Groups [ ${numChannels.toLocaleString()} Channels ]`}
        </p>

        <p>{selectedCount} Groups selected</p>
      </div>
      <section className="overflow-y-auto pr-2">
        <Table dataSource={groups} columns={columns} />
      </section>
    </>
  );
};
