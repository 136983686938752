import { createContext, useCallback, useState } from "react";
import UserRequest from "../../pages/users/UserRequest";
import { useAxiosPrivate } from "../hooks";

const UserRequestContext = createContext();

export const UserRequestProvider = ({ closeHandler, children }) => {
  const [userRequest, setUserRequest] = useState(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [response, setResponse] = useState({});
  const axiosPrivate = useAxiosPrivate();

  const handleCreateUser = useCallback((email) => {
    const user = new UserRequest(email);
    setUserRequest(user);
  }, []);

  const handleNext = useCallback(() => setStepIndex((curr) => ++curr), []);

  const handlePrevious = useCallback(() => setStepIndex((curr) => --curr), []);

  return (
    <UserRequestContext.Provider
      value={{
        response,
        stepIndex,
        userRequest,
        apiClient: axiosPrivate,
        createUser: handleCreateUser,
        onNext: handleNext,
        onPrevious: handlePrevious,
        onClose: closeHandler,
        setResponse,
      }}
    >
      {children}
    </UserRequestContext.Provider>
  );
};

export default UserRequestContext;
