import { useEffect, useRef } from "react";
import { axiosExpress, axiosPrivate } from "../axios";
import { useNavigateHistory } from "../hooks";

const useAxiosPrivate = (isBase = true) => {
  const { loginRedirect } = useNavigateHistory();
  const axios = useRef(isBase ? axiosPrivate : axiosExpress);

  useEffect(() => {
    const _axios = axios.current;
    const reqIntercept = _axios.interceptors.request.use(
      (config) => {
        // const token = localStorage.getItem('token');
        // config.headers.Authorization =  token ? `Bearer ${token}` : '';
        return config;
      },
      (error) => Promise.reject(error)
    );

    const respIntercept = _axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config: originalRequest, response } = error;

        if (response.status === 401 && !originalRequest._retry)
          // the backend return an invalid token
          // only when the refresh token has expired
          loginRedirect();

        return Promise.reject(error);
      }
    );

    return () => {
      _axios.interceptors.request.eject(reqIntercept);
      _axios.interceptors.response.eject(respIntercept);
    };
  }, [loginRedirect]);

  return axios.current;
};

export default useAxiosPrivate;
