import { memo, useEffect, useState } from "react";
import { PiDownloadDuotone as NewIcon } from "react-icons/pi";
import { ServerForm, ServerManager } from ".";
import { Button } from "../../components";
import { useDrawer } from "../../store/hooks";

const ServerList = ({ data }) => {
  const [managers, setManagers] = useState([]);
  const { drawer, openDialog } = useDrawer("server-portal");

  useEffect(() => {
    const addServer = (server) => (
      <ServerManager
        key={server.serverId}
        server={server}
        openDialog={openDialog}
      />
    );

    const addNew = () => (
      <li key="newserver">
        <div className="h-full flex-ij-col">
          <div className="mb-5">
            <Button
              outline
              onClick={() =>
                openDialog({
                  title: "Add New Server",
                  defaultProps: { label: "Add Server" },
                  element: ServerForm,
                  server: {},
                })
              }
            >
              <div className="newserver">
                <span className="avatar">
                  <NewIcon size={48} />
                </span>
                <span className="sbt">Add Server</span>
              </div>
            </Button>
          </div>
          <p className="tcenter">Connect a new server - it only takes</p>
          <p className="tcenter">a few seconds.</p>
        </div>
      </li>
    );

    const __servers = data.map(addServer);
    __servers.push(addNew());
    setManagers(__servers);
  }, [data, openDialog]);

  return (
    <div className="slist">
      <ul>
        {managers} {drawer}
      </ul>
    </div>
  );
};

export default memo(ServerList);
