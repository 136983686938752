import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Animate, Card, Image } from "../../components";
import { useAuth, useCommon, useNavigateHistory } from "../../store/hooks";
import { ForgotPassword } from "./ForgotPassword";
import { LoginForm } from "./LoginForm";
import { ResetForm } from "./ResetForm";

const dashpath = "/repo/dashboard";

const Login = () => {
  const [forgot, setForgot] = useState({ status: false });
  const [trueLocation, setTrueLocation] = useState(null);
  const { auth, setAuth } = useAuth();
  const { established, initialize } = useCommon();
  const { goBack } = useNavigateHistory();

  const location = useLocation();

  useEffect(() => {
    if (auth.loggedOut) return;
    if (auth.token && !auth.mustChange && trueLocation) goBack(trueLocation);
  }, [trueLocation, auth, goBack]);

  const handleContinue = useCallback(
    (from, data = null) => {
      // Navigate to the dashboard if a new user,
      // to the last visited page otherwise.
      let _trueLocation, _data;

      if (data && from === "login") {
        _trueLocation = location.state?.from?.pathname || dashpath;
        _data = data;
        if (!established) initialize();
      } else {
        _trueLocation = dashpath;
        _data = (curr) => ({ ...curr, mustChange: false });
      }

      setTrueLocation(_trueLocation);
      setAuth(_data);
    },
    // eslint-disable-next-line
    [location, established]
  );

  const handleForget = useCallback((status, user) => {
    const state = { status };
    if (user) state.user = user;
    setForgot(state);
  }, []);

  return (
    <div className="login">
      <Card sx={{ width: 428, padding: 32 }}>
        {auth.mustChange ? (
          <Animate id="reset-slide" fade>
            <ResetForm userEmail={auth.email} onContinue={handleContinue} />
          </Animate>
        ) : (
          <>
            <span className="logo">
              <Image logo width={192} />
            </span>
            {forgot.status ? (
              <ForgotPassword onRequested={handleForget} />
            ) : (
              <Animate id="login-slide" fade>
                <LoginForm
                  userEmail={forgot.user}
                  onForget={handleForget}
                  onContinue={handleContinue}
                />
              </Animate>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default Login;
