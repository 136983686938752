import {
  MdOutlineWorkspaces as ServiceIcon,
  MdOutlineSettings as SettingsIcon,
} from "react-icons/md";
import { SlRefresh as RefreshIcon } from "react-icons/sl";
import { DownloadGroupsForm, ServerForm } from ".";
import { IconButton, RepoTooltip } from "../../components";
import Restrict from "../../services/Restrict";
import { useModal } from "../../store/hooks";
import { RepositoryBuild } from "../home/RepositoryBuild";

export function ServerMenu({ modalConfig, onOpenDialog, onStaged }) {
  const { modal, showModal } = useModal(RepositoryBuild, modalConfig);

  return (
    <div className="server-links">
      <RepoTooltip label="Mirth Server Sync" position="bottom">
        <IconButton
          icon={<RefreshIcon size={24} />}
          onClick={() =>
            onOpenDialog({
              title: "Mirth Client API",
              server: modalConfig.server,
              defaultProps: { label: "Stage Groups" },
              element: DownloadGroupsForm,
              onStaged,
            })
          }
        />
      </RepoTooltip>

      <Restrict allowedAction="BuildRepository" hidden>
        <RepoTooltip label="Build Repository" position="bottom">
          <IconButton icon={<ServiceIcon size={24} />} onClick={showModal} />
        </RepoTooltip>
      </Restrict>

      <RepoTooltip label="Settings" position="bottom">
        <IconButton
          icon={<SettingsIcon size={24} />}
          onClick={() =>
            onOpenDialog({
              title: "Mirth Server Settings",
              server: modalConfig.server,
              defaultProps: { label: "Update Server" },
              isUpdate: true,
              element: ServerForm,
            })
          }
        />
      </RepoTooltip>
      {modal}
    </div>
  );
}
