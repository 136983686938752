import { TbCloudDownload as DownloadIcon } from "react-icons/tb";
import { Button, InputField } from "../../components";
import { required } from "../../form/validation-rules";
import { useEiirForm } from "../../store/hooks";

function initialForm(name) {
  const state = {
    apiOrigin: {
      value: name,
    },
    apiUser: {
      value: process.env.REACT_APP_MIRTH_USER,
      rules: [required("Mirth Username"), required("Mirth Password")],
    },
    apiPass: {
      value: process.env.REACT_APP_MIRTH_PASSWORD,
      rules: [required("Mirth Password")],
    },
  };
  return state;
}

export const MirthLoginForm = ({
  children,
  serverName,
  title,
  loading,
  onAuthorize,
}) => {
  const { register, handleSubmit } = useEiirForm(initialForm(serverName));
  const onSubmit = ({ apiUser, apiPass }) => onAuthorize(apiUser, apiPass);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex-ij-col w-full space-y-2"
    >
      <span className="text-primary flex-j m-2 p-2">
        <DownloadIcon size={36} />
      </span>
      <InputField
        {...register("apiOrigin")}
        helperText="Content Server Origin"
        width={252}
        disabled={true}
      />
      <InputField
        {...register("apiUser")}
        placeholder="Mirth Username"
        width={252}
      />
      <InputField
        {...register("apiPass", { type: "password" })}
        placeholder="Mirth Password"
        width={252}
      />
      <Button
        type="submit"
        content={title}
        loading={loading}
        disabled={loading}
        sx={{ width: 252 }}
      />
      <div className="w-[252px] block">{children}</div>
    </form>
  );
};
