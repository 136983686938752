import { IoMdCube as ServersIcon } from "react-icons/io";
import {
  loadPage,
  Panel,
  PanelHeader,
  TabContent,
  Tabs,
} from "../../components";
import { useEiirQuery } from "../../repo-query";
import { Urls } from "../../store/axios";
import { useAxiosPrivate } from "../../store/hooks";
import ServerList from "./ServerList";

const Servers = () => {
  const axiosPrivate = useAxiosPrivate();

  const { data } = useEiirQuery({
    queryKey: ["servers"],
    queryFn: async () => await axiosPrivate(Urls.servers),
    staleTime: 30,
  });

  return (
    <div className="server-container">
      <Panel>
        <PanelHeader
          PanelIcon={ServersIcon}
          title="Server Manager"
          subtitle="Mirth Connect"
        />
      </Panel>

      <Tabs>
        <TabContent label="Info">
          {data === undefined ? (
            <div className="flex-ij-col min-h-[400px]">{loadPage()}</div>
          ) : (
            <ServerList data={data || []} />
          )}
        </TabContent>
      </Tabs>
    </div>
  );
};

export default Servers;
