import { Position } from "@xyflow/react";
import dagre from "dagre";
import { useCallback } from "react";
import FlowRender from "../../services/FlowRender";

export const margin = 40;
export const edgeType = "";

const nodeWidth = 236;
const nodeHeight = 28;

function layoutMirth(nodes, edges, direction) {
  if (direction === "TB") return layoutTree(nodes, edges);
  else {
    const newNodes = [];
    nodes.forEach((node, i) => {
      node.sourcePosition = "bottom";
      node.targetPosition = i === 1 ? "top" : "right";
      node.position = {
        x: node.position.x,
        y: i * margin * 1.5 + (i ? margin : 0),
      };
      newNodes.push(node);
    });
    return newNodes;
  }
}

function layoutTree(nodes, edges) {
  const dGraph = new dagre.graphlib.Graph();
  const direction = "TB";

  dGraph.setDefaultEdgeLabel(() => ({})).setGraph({ rankdir: direction });

  edges.forEach((edge) => dGraph.setEdge(edge.source, edge.target));
  nodes.forEach((node) => {
    dGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  dagre.layout(dGraph);

  const newNodes = nodes.map((node) => {
    const { x, y } = dGraph.node(node.id);
    return {
      ...node,
      targetPosition: Position.Top,
      sourcePosition: Position.Bottom,
      position: { x: x - nodeWidth / 2, y: y - nodeHeight / 2 },
    };
  });

  return newNodes;
}

function createStyledLabel(node, width) {
  let animated = false;
  let label = node
    .replace("COMMON_SERVICES", "CS")
    .replace("EVHC_SOURCE_MSG", "ESM");

  let style = {
    color: "#333",
    border: "1px solid #222138",
    width: width,
  };

  if (label.toLowerCase().includes("mirth")) {
    style.background = "#D6D5E6";
    animated = true;
  }

  return { label, style, animated };
}

const useDagre = ({ direction, fitView }) => {
  const createLayoutFlow = useCallback(
    (onCreate) => {
      let zoom = 1.25;
      const elements = onCreate();
      const layoutedNodes =
        direction === "tree"
          ? elements[0]
          : layoutMirth(elements[0], elements[1], direction);

      return (
        <FlowRender
          initialNodes={layoutedNodes}
          initialEdges={elements[1]}
          fitView={fitView}
          zoom={zoom}
          showControls={false}
        />
      );
    },
    [direction, fitView]
  );

  return { createLayoutFlow };
};

export default useDagre;
export { createStyledLabel, layoutTree };
