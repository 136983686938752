import { useCallback, useState } from "react";
import { Button, Dropdown, InputField } from "../../components";
import { ipCheck, isPort, required } from "../../form/validation-rules";
import { useEiirMutation, useEiirQueryClient } from "../../repo-query";
import { useAxiosPrivate, useEiirForm, useToast } from "../../store/hooks";

function getMessage(id) {
  return {
    instance:
      "The name of the Mirth Connect instance where all the Mirth Channels will be accessible.",
    ipaddress:
      "Local area network that contains broadcasts within itself and only hosts that belong to that VLAN will see those broadcasts.",
    hostname:
      "The URL which allows access to the Mirth Connect Server via a well-defined API.",
    port: "This port is used to communicate with the NextGen Connect Integration Engine Server.",
  }[id];
}

const landingPoints = ["Atlanta Data Center", "AWS Cloud", "Other"];

function init(server) {
  const initialState = {
    instance: {
      value: server.name,
      rules: [required("Server Name")],
    },
    hostname: {
      value: server.hostname,
      rules: [required("Hostname")],
    },
    ipaddress: {
      value: server.ipaddress,
      rules: [required("IP Address"), ipCheck()],
    },
    port: {
      value: server.port,
      rules: [required("Port Number"), isPort()],
    },
    dilp: { value: server.dilp },
  };

  return initialState;
}

export const ServerForm = ({ server, defaultProps, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { element: toastEl, warn, info } = useToast();
  const { register, handleSubmit } = useEiirForm(init(server));
  const axiosPrivate = useAxiosPrivate();
  const eiirQClient = useEiirQueryClient();

  let __mutationFn;
  const mutationKey = ["createServer"];

  if (server.serverId) {
    mutationKey.push({ serverId: server.serverId });
    __mutationFn = async (data) =>
      await axiosPrivate.put(`/servers/${server.serverId}`, data);
  } else {
    __mutationFn = async (data) => await axiosPrivate.post("/servers", data);
  }

  const mutation = useEiirMutation({
    mutationKey,
    mutationFn: async (data) => {
      setLoading(true);
      const resp = await __mutationFn(data);
      return resp.data;
    },
    onSuccess: (data) => {
      eiirQClient.setQueryData("servers", (oldState) =>
        server
          ? oldState.map((el) => (el.serverId === data.serverId ? data : el))
          : [...oldState, data]
      );
      onClose();
    },
    onError: ({ message }) => warn(message),
    onSettled: () => setLoading(false),
  });

  const handleFocus = useCallback((target) => info(getMessage(target)), [info]);

  const onSubmitServer = async (data) => mutation.mutate(data);

  return (
    <form
      id="server-form"
      className="h-screen min-h-full"
      onSubmit={handleSubmit(onSubmitServer)}
    >
      <main className="drawer-section">
        <fieldset className="fs-server">
          <div className="flex3">
            <label htmlFor="instance">
              Server Name
              <InputField
                {...register("instance")}
                helperText="Mirth Connect Instance"
                onFocus={() => handleFocus("instance")}
                width={248}
              />
            </label>
            <div className="mt-4">
              <InputField
                {...register("ipaddress")}
                helperText="VLAN or IP Address (e.g.: 127.0.0.1)"
                onFocus={() => handleFocus("ipaddress")}
                outline
                required
              />
            </div>
          </div>
          <div className="flex3">
            <label>
              Client Hostname
              <InputField
                {...register("hostname")}
                helperText="Hostname or API endpoint"
                onFocus={() => handleFocus("hostname")}
                required
                width={280}
              />
            </label>
            <label>
              Port
              <InputField
                {...register("port")}
                helperText="Administrator Port"
                onFocus={() => handleFocus("port")}
                required
              />
            </label>
          </div>
          <div>
            <label>
              DILP (optional)
              <Dropdown
                id="dilp"
                {...register("dilp", { type: "select" })}
                helperText="Data Ingestion Landing Point"
                sourceItems={landingPoints}
              />
            </label>
          </div>
          {toastEl}
        </fieldset>

        <section className="flex items-center justify-end space-x-6 m-4 pb-2">
          <Button id="cancel-btn" outline content="Cancel" onClick={onClose} />
          <Button
            type="submit"
            id="import-btn"
            content={defaultProps.label}
            loading={loading}
          />
        </section>
      </main>
    </form>
  );
};
