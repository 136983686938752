const Indicator = ({ color }) => {
  const classes = ["indicator"];
  switch (color) {
    case "ok":
      classes.push("bg-green-600");
      break;
    case "primary":
      classes.push();
      break;
    case "progress":
      classes.push("bg-violet-600");
      break;
    case "secondary":
      classes.push("bg-secondary");
      break;
    case "warn":
      classes.push("bg-yellow-500");
      break;
    case "danger":
      classes.push("bg-red-600");
      break;
    default:
      classes.push("bg-neutral-500");
  }

  return <span className={classes.join(" ")}></span>;
};

/**
 * an indicator inside of a badge component.
 */
export const BadgeIndicator = ({ color, label }) => {
  const classes = ["badge-i"];
  switch (color) {
    case "ok":
      classes.push("bg-green-100 text-green-800");
      break;
    case "primary":
      classes.push();
      break;
    case "progress":
      classes.push("bg-violet-100 text-violet-600");
      break;
    case "danger":
      classes.push("bg-red-100 text-red-800");
      break;
    default:
      classes.push("bg-neutral-100 text-neutral-700");
  }

  return (
    <span className={classes.join(" ")}>
      <Indicator color={color} />
      <span className="text-[15px]">{label}</span>
    </span>
  );
};

export default Indicator;
export const Badge = ({ label, children }) => {
  return (
    <section className="relative inline-flex">
      {children}
      {label && (
        <span className="absolute top-1 right-1 px-2 py-1 leading-none translate-x-2/4 -translate-y-2/4 justify-center items-center rounded-full bg-red-500 text-xs text-white">
          {label}
        </span>
      )}
    </section>
  );
};
