import { memo, useCallback, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { GroupInput } from "../../components";

export const UserSearch = memo(({ onApply }) => {
  const [term, setTerm] = useState(null);

  const notifyHandler = useCallback((name) => {
    const updater = (oldState) => {
      return {
        ...oldState,
        filter: {
          term: { operator: "contains", value: name },
        },
      };
    };
    onApply(updater);
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback((name) => {
    if (name !== null) setTerm(name);
  }, []);

  useEffect(() => {
    if (term !== null) notifyHandler(term);
  }, [term, notifyHandler]);

  return (
    <div className="mb-2 ml-20 h-full">
      <GroupInput
        id="usersearch"
        placeholder="Search user by name"
        value={term}
        onChange={handleChange}
        width={300}
        lead={{ icon: <MdSearch size={20} color="gray" /> }}
      />
    </div>
  );
});
