import { useCallback, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Portal,
} from "../../components";
import useOpenState from "./use-openstate";

const useDrawer = (id, callback = null) => {
  const [dialog, setDialog] = useState({});
  const { isOpen, showWindow, dismiss } = useOpenState();
  const { title, large, element, ...props } = dialog;

  const closeWindow = useCallback(() => {
    if (callback) callback();
    setDialog({});
    dismiss();
  }, [callback, dismiss]);

  const openDialog = useCallback(
    (config) => {
      setDialog(config);
      showWindow();
    },
    [showWindow]
  );

  const DrawerContent = element;

  return {
    isOpen,
    drawer: (
      <Portal wrapperId={id || "simple-portal"}>
        <Drawer isOpen={isOpen} large={large}>
          <DrawerHeader title={title} onClose={closeWindow} />
          <DrawerBody>
            {DrawerContent && (
              <DrawerContent onClose={closeWindow} {...props} />
            )}
          </DrawerBody>
          <DrawerFooter />
        </Drawer>
      </Portal>
    ),
    openDialog,
    showWindow,
    closeWindow,
  };
};

export default useDrawer;
