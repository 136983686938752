import { useCallback, useMemo, useState } from "react";
import {
  MdLayers as ListIcon,
  MdGesture as LocationIcon,
  MdOpenInNew as OpenIcon,
} from "react-icons/md";
import {
  IconButton,
  Panel,
  PanelHeader,
  StackedAvatar,
} from "../../components";
import { useCommon, useTable } from "../../store/hooks";
import { LocationDetails } from "./LocationDetails";

const initialHeader = { title: "Service Locations", subtitle: "Facilities" };

const Locations = () => {
  const [header, setHeader] = useState(initialHeader);
  const [locationId, setLocationId] = useState(null);
  const { feeds } = useCommon();

  const handleChange = useCallback((header) => {
    if (header?.location) {
      setLocationId(header.locationId);
      setHeader({ title: header.location, subtitle: header.locationId });
    } else {
      setLocationId(null);
      setHeader(initialHeader);
    }
  }, []);

  const options = useMemo(() => {
    return {
      facility: {
        type: "operator",
        field: "term",
        id: "servicing-fltr",
        label: "Servicing Facility",
      },
      service: {
        type: "select",
        id: "service-fltr",
        field: "service",
        label: "Service Line",
        options: {
          title: "Filter by service line",
          subtitle: "Find location that has a specific line of service.",
          sources: Object.values(feeds || {}).map(
            ({ serviceline }) => serviceline
          ),
        },
      },
    };
  }, [feeds]);

  const columns = useMemo(
    () => [
      {
        label: "Location Id",
        render: (l) => l.locationId,
        align: "text-center",
      },
      {
        label: "Facility",
        render: (l) => l.location,
        sortValue: (l) => l.location,
      },
      {
        label: "ecode",
        render: (l) => l.ecode,
        sortValue: (l) => l.ecode,
      },
      {
        label: "Exchange Count",
        render: (l) => <span>{l.types}</span>,
        align: "align-middle text-center py-2",
      },
      {
        label: "Service Lines",
        render: (l) => {
          return (
            <section className="py-1">
              <StackedAvatar
                labels={l.subservices ? l.subservices.split(", ") : []}
              />
            </section>
          );
        },
      },
      {
        key: "Action",
        render: (l) =>
          l.types > 0 && (
            <IconButton
              title="View location details"
              icon={<OpenIcon size={20} />}
              sx={{ margin: "5px 10px" }}
              onClick={() => handleChange(l)}
            />
          ),
      },
    ],
    [handleChange]
  );

  const tableConfig = { columns, options, resource: "locations" };
  const { createTable } = useTable(tableConfig);
  const isTable = locationId === null;

  return (
    feeds.length > 0 && (
      <div className="w-full space-y-4">
        <Panel>
          <PanelHeader
            PanelIcon={
              header.subtitle === "Facilities" ? ListIcon : LocationIcon
            }
            title={header.title}
            subtitle={header.subtitle}
          />
        </Panel>
        {!isTable && (
          <LocationDetails
            locationId={locationId}
            onClose={() => handleChange()}
          />
        )}
        {createTable(columns, isTable)}
      </div>
    )
  );
};

export default Locations;
