import { Panel, PanelHeader } from "../components";

export const EiirPage = ({ className, title, subtitle, icon, children }) => {
  return (
    <div className={className || "w-full space-y-4"}>
      <Panel>
        <PanelHeader PanelIcon={icon} title={title} subtitle={subtitle} />
      </Panel>
      {children}
    </div>
  );
};
