import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import "@fontsource/rambla";
import "@fontsource/roboto";
import "@xyflow/react/dist/style.css";
import "./App.main.css";
import {
  AuthProvider,
  CommonProvider,
  QueryClientProvider,
} from "./store/contexts";
import { EiirQueryClient } from "./repo-query/EiirQueryClient";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const eiirQueryClient = new EiirQueryClient();

root.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={eiirQueryClient}>
        <AuthProvider>
          <CommonProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </CommonProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>
);
