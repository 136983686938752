import { useEffect, useState } from "react";
import { GroupList, MirthLoginForm } from ".";
import { useEiirMutation } from "../../repo-query";
import { useAxiosPrivate, useToast } from "../../store/hooks";
import { Button } from "../../components";

const DownloadGroupsForm = ({ server, onStaged, onClose }) => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [groups, setGroups] = useState([]);
  const [session, setSession] = useState({});
  const { element, isError, clear, warn } = useToast("dlg-toast");

  const axiosPrivate = useAxiosPrivate();

  const isChannel = server.dataImport?.isChannel;
  const urlPrefix = `/servers/${server.serverId}`;

  const mutator = isChannel
    ? {
        mutationKey: ["udpateChannels"],
        mutationFn: async (creds) => {
          creds.session = server.dataImport.session;
          return await axiosPrivate.post(
            `/mirth/channels/${server.serverId}`,
            creds
          );
        },
        onSuccess: () => null,
        onError: (error) => warn(error.message),
      }
    : {
        mutationKey: ["importGroups"],
        mutationFn: async (creds) =>
          await axiosPrivate.post(`${urlPrefix}/channelgroups`, creds),
        onSuccess: ({ sessionId, groups }) => {
          let numChannels = 0,
            checked = false,
            items = groups.map((g) => {
              numChannels += g.channels.count;
              return {
                uuid: g.uuid,
                name: g.name,
                count: g.channels.count,
                imported: g.channels.imported,
                checked,
              };
            });

          setGroups(items);
          setSession({ id: sessionId, numChannels });
        },
        onError: (error) => warn(error.message),
      };

  const downloadMutation = useEiirMutation(mutator);

  const stageMutation = useEiirMutation({
    mutationkey: ["stageGroups"],
    mutationFn: async ({ sessionId, data }) =>
      await axiosPrivate.post(`${urlPrefix}/staging`, {
        session: sessionId,
        selectedGroups: data,
      }),
    onSuccess: ({ data }) => {
      onStaged(data);
      onClose();
    },
    onError: ({ message }) => {
      warn(message);
    },
  });

  const logOutMutation = useEiirMutation({
    mutationKey: ["logoutMirth"],
    mutationFn: async (data) => axiosPrivate.post(`${urlPrefix}/logout`, data),
  });

  const handleAuthorize = async (username, password) => {
    downloadMutation.mutate({ username, password });
    clear();
  };

  const handleStageGroups = () => {
    const payload = groups.filter((g) => g.checked).map((g) => g.uuid);
    if (payload.length > 0)
      stageMutation.mutate({ sessionId: session.id, data: payload });
  };

  const handleCancel = () => {
    logOutMutation.mutate({ session: session.id });
    onClose();
  };

  useEffect(() => {
    const count = groups.reduce((sum, item) => sum + (item.checked ? 1 : 0), 0);
    setSelectedCount(count);
  }, [groups]);

  return (
    <>
      {isError ? (
        <div className="px-5">{element}</div>
      ) : session.id ? (
        <div className="px-2 relative flex flex-col h-full space-y-6 overflow-y-auto">
          <GroupList
            groups={groups}
            updateGroups={setGroups}
            numChannels={session.numChannels}
            selectedCount={selectedCount}
          />
          <section className="flex items-center justify-end space-x-6 px-6 pb-4">
            <Button
              id="cancel-btn"
              outline
              content="Cancel"
              onClick={handleCancel}
            />
            <Button
              id="import-btn"
              content="Stage Groups"
              onClick={handleStageGroups}
              loading={stageMutation.isLoading}
              disabled={selectedCount === 0}
            />
          </section>
        </div>
      ) : (
        <MirthLoginForm
          serverName={server.name}
          title={
            downloadMutation.isLoading
              ? "Logging into Mirth instance"
              : isChannel
              ? "Update Mirth Channels"
              : "Download Channel Groups"
          }
          loading={downloadMutation.isLoading}
          onAuthorize={handleAuthorize}
        />
      )}
    </>
  );
};

export default DownloadGroupsForm;
