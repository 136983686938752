import { useCommon, useNavigateHistory } from "../../store/hooks";
import { DashboardCards } from "./DashboardCards";
import { ExchangeCard } from "./ExchangeCard";
import { ServiceCard } from "./ServiceCard";

const Dashboard = () => {
  const { goTo } = useNavigateHistory();
  const {
    hospitals,
    destinations,
    exchangeTypes,
    feeds,
    servers,
    feedCount,
    channelCount,
  } = useCommon();

  return (
    <div className="dashboard">
      <div className="grid grid-cols-1 py-4 leading-tight">
        <span className="h8">Hi, welcome!</span>
        <span>Your Enterprise Interface Inventory Repository.</span>
      </div>
      <DashboardCards
        config={{ destinations, feedCount, channelCount }}
        onNavigate={(url) => goTo(url)}
      />
      <div className="flex space-x-4 h-full">
        <ExchangeCard
          config={{
            hospitals,
            exchangeTypes,
            feedCount,
            channelCount,
            serverCount: servers.length,
          }}
          onNavigate={() => goTo("/repo/reports")}
        />
        <ServiceCard feeds={feeds} />
      </div>
    </div>
  );
};

export default Dashboard;
