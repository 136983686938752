import { Button } from ".";
import { IoIosArrowDroprightCircle as CloseIcon } from "react-icons/io";
import { MdAddTask as FormIcon } from "react-icons/md";

const scrollMargin = "mr-[17px]";

export const DrawerHeader = ({ title, onClose }) => {
  return (
    <header>
      {title && (
        <div className="drawer-header">
          <FormIcon size={26} style={{ marginRight: 8 }} />
          {title}
        </div>
      )}
      <span className="close-icon">
        <Button outline onClick={() => onClose()}>
          <CloseIcon size={32} />
        </Button>
      </span>
    </header>
  );
};

export const DrawerBody = ({ children }) => {
  return <div className="drawer-body">{children}</div>;
};

export const DrawerFooter = () => {
  return <div />;
};

const Drawer = ({ isOpen, large, children }) => {
  const home = document.getElementById("home"); // home <div> element
  const scrollbarVisible =
    home.getBoundingClientRect().width > home.clientWidth;

  if (isOpen) {
    if (scrollbarVisible) home.classList.add(scrollMargin);
    document.body.style.overflow = "hidden";
  } else {
    if (scrollbarVisible) home.classList.remove(scrollMargin);
    document.body.style.overflow = "auto";
  }

  return (
    <main className={`drawer${isOpen ? "" : " close"}`}>
      <section className={`drawer-content${large ? " document" : ""}`}>
        {children}
      </section>
      <section className="overlay"></section>
    </main>
  );
};

export default Drawer;
