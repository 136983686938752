import { useRef, useState } from "react";
import { IoMdClose as CloseIcon } from "react-icons/io";
import { TbFilter as FilterIcon } from "react-icons/tb";
import { IconButton } from "..";
import { useDropContainer } from "../../store/hooks";

export function withCustom(WrappedComponent, props) {
  return function ({ name, onNotify }) {
    return <WrappedComponent key={name} {...props} onApply={onNotify} />;
  };
}

export function withFilter(WrappedComponent, props, defaultProps) {
  return function ({ name, icon, onNotify }) {
    const voidUpdater = useRef(null);
    const [display, setDisplay] = useState(null);
    const [droparea, divRef, showContainer, closeWindow] = useDropContainer(
      `${name}-queryfilter`
    );

    const handleClear = () => {
      setDisplay(null);
      onNotify(voidUpdater.current);
    };

    function handleProxySubmit(fnSubmit) {
      return async (event) => {
        event.preventDefault();

        const { value, fnVoid, updater } = await fnSubmit();
        voidUpdater.current = fnVoid;

        setDisplay(value);
        onNotify(updater);
        closeWindow();
      };
    }

    const CurrIcon = icon || FilterIcon;

    return (
      <div
        id={name}
        ref={divRef}
        className={`sfilter border${display ? "" : "-dashed"}`}
      >
        <IconButton
          position="left"
          label={props.label?.toLowerCase()}
          icon={<CurrIcon size={20} />}
          onClick={() =>
            showContainer({
              content: (
                <WrappedComponent
                  {...defaultProps}
                  {...props}
                  onApply={handleProxySubmit}
                />
              ),
            })
          }
        />
        {droparea}
        {display && (
          <div className="selection">
            <span className="mr-1">{display}</span>
            <IconButton
              icon={<CloseIcon />}
              sx={{ margin: 0, padding: 0 }}
              onClick={handleClear}
            />
          </div>
        )}
      </div>
    );
  };
}
