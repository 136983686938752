import { memo, useState } from "react";
import { BsPatchCheck as LimitedIcon } from "react-icons/bs";
import {
  MdOutlineCheckCircleOutline as CheckIcon,
  MdNavigateNext as NextIcon,
  MdOutlineRemoveDone as RemoveIcon,
} from "react-icons/md";
import { Button, Divider, Toast } from "../../components";
import { useUserRequest } from "../../store/hooks";
import { useEiirMutation } from "../../repo-query";

export const UserReview = memo(() => {
  const [error, setError] = useState(null);
  const { apiClient, userRequest, setResponse, onNext, onPrevious } =
    useUserRequest();

  const newUserMutation = useEiirMutation({
    mutationKey: ["createProfile"],
    mutationFn: async (newUser) =>
      await apiClient.post("users", newUser.toJSON()),
    onSuccess: (data) => {
      setResponse(data);
      onNext();
    },
    onError: (error) => {
      console.log(error);
      setError(error.message);
    },
  });

  if (!userRequest) return;

  const reviews = [
    {
      key: "acct-review",
      label: "Display & Username",
      content: (
        <>
          <p>{userRequest.name.display}</p>
          <p>{userRequest.username}</p>
        </>
      ),
    },
    {
      key: "role-review",
      label: "Role",
      content: <p>{userRequest.role}</p>,
    },
    {
      key: "dept-review",
      label: "Department",
      content: <p>{userRequest.department.name}</p>,
    },
  ];

  const tags = Object.values(userRequest.policies() || {}).reduce(
    (reduceObj, { category, allowed }) => {
      const curr = reduceObj[category] || { allowed: 0, denied: 0 };
      allowed ? curr.allowed++ : curr.denied++;
      reduceObj[category] = curr;
      return reduceObj;
    },
    {}
  );

  const handleCreate = async () => newUserMutation.mutate(userRequest);

  return (
    <section className="drawer-section">
      <div className="review">
        <p className="title">
          Almost done! Review all the info for this user before adding.
        </p>
        {error && (
          <div className="toast">
            <Toast warning message={error} />
          </div>
        )}
        <div className="grid grid-cols-2 gap-3 my-10">
          <ol className="mx-5 tracking-tight leading-tight flex flex-col space-y-5">
            {reviews.map((review) => {
              return (
                <li key={review.key} className="text-end">
                  <p className="font-semibold text-[15px]">{review.label}</p>
                  {review.content}
                </li>
              );
            })}
          </ol>
          <div>
            <p className="font-semibold mb-1 text-[15px]">Permissions</p>

            <ul className="flex flex-col space-y-1.5 list-disc">
              {Object.entries(tags).map(([tag, count]) => {
                const { allowed, denied } = count;
                const icon = allowed ? (
                  denied ? (
                    <LimitedIcon size={18} color="#CC5500" />
                  ) : (
                    <CheckIcon size={18} color="green" />
                  )
                ) : (
                  <RemoveIcon size={18} color="gray" />
                );

                return (
                  <li key={tag} className="flex items-center space-x-1">
                    {icon}
                    <span>{tag}</span>
                    <span className="text-secondary tracking-normal pl-2">
                      ({count.allowed}/{count.allowed + count.denied})
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="flexj space-x-2 mb-5">
        <Button onClick={onPrevious}>
          <div className="stepbtn">
            <span className="-scale-x-100">
              <NextIcon size={20} />
            </span>
            <span className="label pr-1">PREVIOUS</span>
          </div>
        </Button>
        <Divider />
        <Button onClick={handleCreate} loading={newUserMutation.isLoading}>
          <div className="stepbtn">
            <span className="label pl-0.5">CREATE USER</span>
          </div>
        </Button>
      </div>
    </section>
  );
});
