import { memo } from "react";
import { RiUserSettingsLine as AccountIcon } from "react-icons/ri";
import { TbArrowBackUpDouble as BackIcon } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Panel,
  PanelHeader,
  Skeleton,
} from "../../components";
import { useEiirQuery } from "../../repo-query";
import { useAleiirt, useAuth, useAxiosPrivate } from "../../store/hooks";
import { ShowPassword } from "./ShowPassword";
import { ShowPolicies } from "./ShowPolicies";
import { ShowProfile } from "./ShowProfile";

const UserAccount = ({ userId, onReturn }) => {
  const { setAlertMessage } = useAleiirt();
  const { auth } = useAuth();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const { publicId } = location.state || {};
  const currId = publicId || userId;

  const handleUpate = (message) => setAlertMessage(message);

  const { data: profile, isFetching } = useEiirQuery({
    queryKey: ["users", { currId }],
    queryFn: async () => await axiosPrivate(`/users/${currId}/profile`),
    onError: (error) => console.log(error),
    staleTime: 15,
  });

  if (!currId) return;
  const isSelf = currId === auth.publicId;

  return (
    <>
      <Panel>
        <PanelHeader>
          <div className="flex-ij space-x-2">
            {isSelf ? (
              <div className="hpanelicon">
                <AccountIcon />
              </div>
            ) : (
              <Button outline sx={{ width: 120 }} onClick={() => onReturn()}>
                <div className="flex space-x-1 items-center">
                  <BackIcon size={20} />
                  <span>Return to list</span>
                </div>
              </Button>
            )}
            <Divider />
            <div className="pl-2">
              <p className="sm-text">Account</p>
              <p className="t23m">User Profile</p>
            </div>
          </div>
        </PanelHeader>
      </Panel>

      <div className="flex space-x-3 w-full min-h-[600px] mb-20 tracking-tight">
        <div className="w-full min-h-96 flex flex-col space-y-3">
          {isFetching ? (
            <Skeleton list={{ rows: 5, cols: 2 }} />
          ) : profile ? (
            <>
              <ShowProfile
                isSelf={isSelf}
                account={profile.account}
                onUpdate={handleUpate}
              />
              {isSelf && <ShowPassword onUpdate={handleUpate} />}
            </>
          ) : null}
        </div>
        <div>
          {isFetching ? (
            <Skeleton list={{ rows: 5, cols: 1 }} />
          ) : (
            <ShowPolicies
              publicId={userId}
              permissions={profile?.permissions || {}}
              onUpdate={handleUpate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(UserAccount);
