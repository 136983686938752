import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../store/hooks";
import { loadPage } from "../components";
import { useRef } from "react";

/**
 * Check if JWT token every time the Route changes
 * and call the logOut event if token is expired.
 *
 * If the route is allowed and the user is authenticated, then move to the Outlet.
 * Otherwise, if the route is not allowed, and the user is authenticated,
 * then access is not authorized. In any other event, naviate to the login page.
 *
 */
const RequireAuth = ({ allowedRank = 1 }) => {
  const location = useLocation();
  const refreshRef = useRef(false);
  const { auth, isAuthenticated, verifyRefreshToken } = useAuth();
  const { token, rank, loggedOut } = auth || {};

  const toLocation = (to) => (
    <Navigate to={to} state={{ from: location }} replace />
  );

  // If logging out
  // just exit to the Login page
  if (loggedOut) return toLocation("/");

  if (token === undefined) return;

  // Verify user is authenticated and authorized
  if (rank >= allowedRank) {
    if (isAuthenticated(token)) {
      return <Outlet />;
    } else {
      if (auth.isRefreshToken) {
        // user is unauthenticated or token session expired
        return toLocation("/expired");
      } else {
        if (!refreshRef.current) {
          refreshRef.current = true;
          Promise.resolve()
            .then(() =>
              verifyRefreshToken().catch(({ response }) => {
                console.log(response);
              })
            )
            .finally(() => (refreshRef.current = false));
        }
      }
    }
  } else return toLocation("/repo/unauthorized");

  return loadPage();
};

export default RequireAuth;
