import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Button } from "../../components";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-3 bg-white border-2 border-dotted leading-tight text-sm">
        <p>{payload[0].payload.alias}</p>
        <p className="text-[#560BD0]">Active: {payload[0].value}</p>
        {payload.length > 1 && (
          <p className="text-[#6e7280]">Inactive: {payload[1].value}</p>
        )}
      </div>
    );
  }
  return null;
};

export const DashboardCards = ({ config, onNavigate }) => {
  const { feedCount, channelCount, serverCount, destinations } = config;

  return (
    <div className="dashboard-panel">
      <div className="container">
        <div className="h-full grid grid-cols-1 content-between">
          <div>
            <p className="text-[16px] text-neutral-700 font-semibold">
              Total Channels
            </p>
            <div className="description">
              <span>100%</span>
              <span className="ml-1 text-neutral-500">
                across {serverCount} Mirth Server
              </span>
            </div>
          </div>
          <div className="flexib mr-8">
            <p className="count">{channelCount?.toLocaleString()}</p>
            <Button
              outline
              content="View Channels"
              onClick={() => onNavigate("/repo/channels")}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="h-full grid grid-cols-1 content-between">
          <div>
            <p className="text-[16px] text-neutral-700 font-semibold">
              DIS Feeds
            </p>
            <p className="description text-neutral-500">
              The total number of inbound and outbound feeds
            </p>
          </div>
          <div className="flexib mr-8">
            <span className="count">{feedCount.toLocaleString()}</span>
            <Button
              outline
              content="View Mirth Feeds"
              onClick={() => onNavigate("/repo/feeds")}
            />
          </div>
        </div>
      </div>

      <div className="container relative">
        <div className="absolute left-0 top-0 m-4 z-0">
          <p className="dash-title">Destination Types</p>
          <p className="dash-description w-[60%]">
            Active (inactive) destination connectors
          </p>
        </div>

        <section className="w-full h-[84%] absolute bottom-0 pb-2 -ml-4 z-1">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={destinations}>
              <CartesianGrid horizontal={false} vertical={false} />
              <Tooltip content={<CustomTooltip />} />
              <Bar yAxisId="left" dataKey="active" fill="#560BD0" />
              <Bar yAxisId="right" dataKey="inactive" fill="#CAD0E8" />
            </BarChart>
          </ResponsiveContainer>
        </section>
      </div>

      <div className="container-list">
        {destinations
          ?.map((x, i) => {
            return (
              x.active && (
                <div
                  key={x.connector}
                  className={`connector${i % 2 ? " bg-neutral-50" : ""}`}
                >
                  <div>{x.alias}</div>
                  <span className="text-end">{x.active?.toLocaleString()}</span>
                </div>
              )
            );
          })
          .filter(Boolean)}
      </div>
    </div>
  );
};
