import { useCallback, useRef, useState } from "react";
import { Accordion, Button } from "../../components";
import {
  useAleiirt,
  useAuth,
  useAxiosPrivate,
  useOnce,
} from "../../store/hooks";
import { PolicyRow } from "./PolicyRow";
import { useEiirMutation } from "../../repo-query";

export const ShowPolicies = ({ publicId, permissions, onUpdate }) => {
  const [editing, setEditing] = useState(false);
  const [toggles, setToggles] = useState({});
  const { setErrorMessage } = useAleiirt();
  const {
    auth: { permissions: allowedActions },
  } = useAuth();

  const isMounted = useRef(false);
  const axiosPrivate = useAxiosPrivate();

  useOnce(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    if (permissions) setToggles({ actions: permissions });
  });

  const mutator = useEiirMutation({
    mutationFn: async ({ id, actions }) =>
      await axiosPrivate.patch(`/users/${id}/actions`, actions),
    onSuccess: ({ message }) => {
      onUpdate(message);
      setEditing(false);
    },
    onError: ({ message }) => setErrorMessage(message),
  });

  const handleToggle = useCallback((category, id, allowed) => {
    setEditing(true);
    setToggles((curr) => {
      const categoryObj = curr.actions[category][id];
      return {
        actions: {
          ...curr.actions,
          [category]: {
            ...curr.actions[category],
            [id]: { ...categoryObj, allowed },
          },
        },
        changes: {
          ...curr.changes,
          [id]: allowed,
        },
      };
    });
  }, []);

  const handleUpdate = () =>
    mutator.mutate({ id: publicId, actions: toggles.changes });

  const handleCancel = () => {
    setEditing(false);
    setToggles({ actions: permissions });
  };

  const items = Object.entries(toggles.actions || []).map(
    ([category, values]) => {
      const renderActions = [];

      for (let action of Object.values(values)) {
        renderActions.push(
          <PolicyRow
            key={action.actionId}
            category={category}
            action={action}
            editable={!!allowedActions["EditUserPolicies"]}
            onToggle={handleToggle}
          />
        );
      }

      return (
        renderActions.length > 0 && {
          label: category,
          content: (
            <div className="w-full grid grid-cols-1 gap-0 mr-2.5 pr-2.5 pl-1.5">
              {renderActions}
            </div>
          ),
        }
      );
    }
  );

  return (
    <div className="flex flex-col space-y-2 w-[300px]">
      <Accordion items={items} />
      <div
        className={`flex-i justify-end space-x-1 transition-opacity duration-300 ${
          editing ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        <Button content="Cancel" outline onClick={handleCancel} />
        <Button
          content="Confirm"
          loading={mutator.isLoading}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};
