class UserRequest {
  constructor(email) {
    this.email = email;
    this._policies = {};
    this.name = {};
    this.identifiers = [];
    this.telecom = [];
    this.role = null;
    this.department = { title: "", name: "" };

    this.setCredentials = this.setCredentials.bind(this);
  }

  setCredentials(username, temppwd) {
    this.username = username;
    this._temp = temppwd;
    this.identifiers.push({
      type: "CAI",
      value: { username, temppwd },
    });
  }
  setName(first, last, display) {
    this.name = {
      family: last,
      given: first,
      display,
    };
  }
  setDepartment(title, name) {
    this.department = { title, name };
  }
  setRole(role) {
    this.role = role;
  }
  addPhone(number) {
    if (number) this.telecom.push({ system: "phone", value: number });
  }
  /**
   * @param {string} rules
   */
  set permissions(rules) {
    this._policies = rules;
  }
  revokePassword() {
    if (this.identifiers.length > 0) {
      let creds = this.identifiers[0];
      creds.mustChange = true;
    }
  }
  get permissions() {
    return Object.entries(this._policies).map(([id, action]) => {
      return { id, allowed: action.allowed };
    });
  }
  temp() {
    return this._temp;
  }
  policies() {
    return this._policies;
  }
  hasPolicies() {
    return Object.keys(this._policies).length > 0;
  }
  toJSON() {
    this.telecom.push({ system: "email", value: this.email });

    return {
      identifiers: this.identifiers,
      login: {
        accessType: "Repo Auth",
        accountStatus: "Unlocked",
      },
      department: this.department,
      name: {
        family: this.name.family,
        given: [this.name.given],
        display: this.name.display,
      },
      gender: "unknown",
      telecom: this.telecom,
      role: this.role,
      permissions: this.permissions,
      policies: [],
      status: "A",
    };
  }
}

export default UserRequest;
