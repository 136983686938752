import { useState } from "react";
import { RiCloseFill as CloseIcon } from "react-icons/ri";
import { Button, Panel, PanelBody, PanelHeader } from "../../components";
import { useDagre, useOnce } from "../../store/hooks";
import { serviceNodes } from "./elements";

function PanelItem({ title, value }) {
  return (
    <div className="pl-3">
      <p className="lead-title">{title}</p>
      <p className="tracking-tight">{value}</p>
    </div>
  );
}

export const ServiceSummary = ({ service, onClose }) => {
  const [flow, setFlow] = useState(null);
  const { createLayoutFlow } = useDagre({ direction: "tree" });

  useOnce(() => {
    const flow = createLayoutFlow(() => serviceNodes(service));
    setFlow(flow);
  });

  return (
    <div className="space-y-3 mt-12">
      <div className="service-flow">
        {flow}
        <div className="absolute left-1/2 top-0 -mt-3">
          <Button
            icon={{ icon: CloseIcon, size: 18 }}
            title="Close window"
            onClick={onClose}
          />
        </div>
      </div>

      <Panel>
        <PanelHeader>
          <span>Interface Id: #{service.serviceId}</span>
        </PanelHeader>
        <PanelBody>
          <div className="w-full ml-4 grid grid-cols-4 divide-x divide-neutral-400 leading-tight py-2">
            <div>
              <p className="lead-title">eCode</p>
              <p className="tracking-tight">{service.ecode || "-"}</p>
            </div>
            <PanelItem title="ConnectId" value={service.connectId || "-"} />
            <PanelItem title="COCID" value={service.cocid} />
            <PanelItem title="Location" value={service.location.locationId} />
          </div>
          <div className="w-full ml-4 grid grid-cols-4 divide-x divide-neutral-400 leading-tight tracking-tight mb-3 mt-2">
            <div>
              <p className="lead-title">Location Name</p>
              <p className="pr-3">{service.location.name}</p>
            </div>
            <PanelItem
              title="Health System"
              value={service.mirthfeed.healthsystem}
            />
            <PanelItem
              title="Data Exchange Type"
              value={service.mirthfeed.exchangeType.name}
            />
            <PanelItem
              title="Data Format"
              value={service.mirthfeed.dataformat}
            />
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};
