import { useEffect, useMemo } from "react";
import { Button, LoadSpinner } from "../../components";
import { useEiirMutation } from "../../repo-query";
import {
  useAleiirt,
  useAxiosPrivate,
  useCallWithRetry,
} from "../../store/hooks";

const importCodes = ["R", "W"];

function ServerNotify({ label }) {
  return (
    <div className="server-progress">
      <span>This may take a few minutes.</span>
      <p>{label}</p>
    </div>
  );
}

export const ServerProgress = ({
  serverId,
  staging,
  inProgress,
  onSuccess,
  onStart,
  onStop,
}) => {
  const { setAlertMessage, setErrorMessage } = useAleiirt();
  const axiosPrivate = useAxiosPrivate();

  const stagingUrl = useMemo(
    () => `/servers/${serverId}/staging/${staging?.stagingId}`,
    [serverId, staging.stagingId]
  );

  const { withRetry } = useCallWithRetry({
    predicate: (url) =>
      new Promise(async (resolve, reject) => {
        try {
          const { data } = await axiosPrivate(url);
          return data.status === "S"
            ? resolve(data.server)
            : data.status === "W"
            ? resolve(null)
            : reject(data.detail);
        } catch (error) {
          return reject(error);
        }
      }),
    onComplete: (server) => onSuccess(server),
    onSettled: onStop,
  });

  const importMutation = useEiirMutation({
    mutationKey: ["importGroups", { serverId }],
    mutationFn: async (url) => await axiosPrivate.post(url, {}),
    onSuccess: (data, url) => {
      // updated Mirth server status and count
      const { data: server, message, status } = data || { data: {} };
      if (server.invoked) {
        setAlertMessage(message);
        withRetry(url);
      } else {
        if (status === "success") setAlertMessage(message);
        else setErrorMessage(message);
        onSuccess(server);
        onStop();
      }
    },
    onError: (error) => setErrorMessage(error.message),
  });

  useEffect(() => {
    const { status } = staging || {};
    if (importCodes.includes(status)) importMutation.mutate(stagingUrl);
    // eslint-disable-next-line
  }, [serverId, staging]);

  return (
    <div className="flex mx-3 px-3">
      {staging && (
        <>
          <ServerNotify label="Updating Repository..." />
          {inProgress ? (
            <LoadSpinner />
          ) : (
            <div className="flex-ij">
              <Button
                content={inProgress ? "Checking..." : "Check Status!"}
                loading={inProgress}
                onClick={() => {
                  onStart();
                  withRetry(stagingUrl);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
