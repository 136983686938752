import { useEffect, useState } from "react";
import { BsPersonLock as LockIcon } from "react-icons/bs";
import {
  FiCheckCircle as CheckIcon,
  FiKey as KeyIcon,
  FiCornerUpLeft as LeftIcon,
} from "react-icons/fi";
import { Button, IconAvatar, IconButton, InputField } from "../../components";
import { required } from "../../form/validation-rules";
import { Urls } from "../../store/axios";
import {
  useAttempt,
  useAuth,
  useAxiosPrivate,
  useEiirForm,
  useRequirements,
  useToast,
} from "../../store/hooks";
import { useEiirMutation } from "../../repo-query";

const threshold = 5;

const resetFormConfig = {
  currentpwd: { rules: [required("Current Password")] },
  newpwd: { rules: [required("New Password")] },
  confirmpwd: { rules: [required("Confirm Password")] },
};

export const ResetForm = ({ userEmail, onContinue }) => {
  const [resetRequest, setResetRequest] = useState(false);
  const { isBelowThreshold, recordMistake } = useAttempt(threshold);
  const { values, register, handleSubmit } = useEiirForm(resetFormConfig);
  const { checkList, onValidate, onVerify } = useRequirements();
  const { element, clear, warn } = useToast("reset-toast");
  const { lockAccount, logout } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const mutation = useEiirMutation({
    mutationKey: ["resetPassword"],
    mutationFn: async ({ current, updated }) =>
      await axiosPrivate.patch(Urls.resetPassword, { current, updated }),
    onSuccess: () => setResetRequest(true),
    onError: ({ message }) => {
      recordMistake(userEmail, null);
      warn(message);
    },
  });

  useEffect(() => {
    if (!isBelowThreshold) {
      warn(
        <span>
          The password for this account was not changed.
          <br />
          Please contact System Administrator for login assistance.
        </span>
      );
      if (lockAccount(userEmail, "failed to reset password"));
    }
  }, [isBelowThreshold, userEmail, lockAccount, warn]);

  useEffect(() => clear(), [values, clear]);

  const onSubmit = async (data) => {
    clear();

    const { currentpwd, newpwd, confirmpwd } = data;
    const errorMessage = onVerify(newpwd, newpwd === confirmpwd);

    if (isBelowThreshold) {
      if (errorMessage) {
        warn(errorMessage);
        recordMistake(userEmail);
      } else mutation.mutate({ current: currentpwd, updated: newpwd });
    }
  };

  return (
    <form id="update-pwdform" onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        {resetRequest ? (
          <>
            <div className="avatar">
              <IconAvatar
                Icon={CheckIcon}
                color="green"
                size={20}
                sx={{ padding: 8 }}
              />
            </div>
            <p className="header">Password reset</p>
            <p className="info">
              Your password has been succesfully reset.
              <br /> Click below to log into the dashboard.
            </p>
            <Button content="Continue" onClick={() => onContinue("reset")} />
          </>
        ) : (
          <>
            <div className="avatar">
              <IconAvatar
                Icon={isBelowThreshold ? KeyIcon : LockIcon}
                color={isBelowThreshold ? "green" : "red"}
                size={24}
                sx={{ padding: 7 }}
              />
              <div className="header">Reset Password</div>
            </div>

            {element}

            <div className="grid grid-cols-1 gap-y-3">
              <InputField
                {...register("currentpwd", { type: "password" })}
                label="Old Password"
                helperText="Your current password"
              />
              <InputField
                {...register("newpwd", {
                  type: "password",
                  onChange: (_, value) => onValidate(value),
                })}
                label="New Password"
              />
              <div className="pb-2">{checkList}</div>
              <InputField
                {...register("confirmpwd", { type: "password" })}
                label="Confirm Password"
                helperText="Must match new password"
              />
            </div>
            <Button
              type="submit"
              content="Update Password"
              loading={mutation.isLoading}
              disabled={!isBelowThreshold}
            />
          </>
        )}
        <IconButton
          icon={<LeftIcon />}
          position="left"
          label="Back to log in"
          onClick={logout}
        />
      </fieldset>
    </form>
  );
};
