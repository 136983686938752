import { createContext, useCallback, useState } from "react";
import apiClient, { headers, JsonResponse, Urls } from "../axios";
import { useLocalStorage, useNavigateHistory } from "../hooks";

const AuthContext = createContext({});
const initialStorage = { persist: false, eiirEmail: null };

async function getIp() {
  const response = await fetch(Urls.lockIp);
  const { ip } = await response.json();
  return ip;
}

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [isRefreshing, setIsRefresing] = useState(false);
  const [storage, setStorageItem] = useLocalStorage(initialStorage);
  const { loginRedirect } = useNavigateHistory();

  const login = async (user, pwd) => {
    // To post the FormData using axios,
    // you need specify in header that you are sending FormData,
    // for that content-type should be multipart/form-data.
    try {
      let config = {
        headers,
        withCredentials: true,
        auth: { username: user, password: pwd },
      };
      const resp = await apiClient.post(
        "/users/auth",
        { username: user, password: pwd },
        config
      );

      return JsonResponse(resp);
    } catch ({ code, response }) {
      const { data, status } = response || {
        data: { detail: "(500) Internal Server Error" },
      };

      return code === "ERR_NETWORK"
        ? {
            status: status || 503,
            code: code || "Network Error",
            message: "Repository Service Unavailable",
          }
        : {
            status,
            message: data.detail,
          };
    }
  };

  const logout = useCallback(() => {
    setAuth({ loggedOut: true });
    apiClient.post("/users/logout", {}, { withCredentials: true });
  }, []);

  const verifyRefreshToken = useCallback(
    async (callback = null) => {
      const config = { headers, withCredentials: true };
      try {
        setIsRefresing(true);
        const { data } = await apiClient.get(Urls.refresh, config);
        setAuth({ ...data, isRefreshToken: true });
        if (callback) callback();
      } catch (error) {
        loginRedirect();
        throw error;
      } finally {
        setIsRefresing(false);
      }
    },
    [loginRedirect]
  );

  const parseJWT = (accessToken) => {
    try {
      const jwtDecoded = atob(accessToken.split(".")[1]);
      return JSON.parse(jwtDecoded);
    } catch ({ response }) {
      if (response.status === 403) console.log(response.data);
      return {};
    }
  };

  const isAuthenticated = (token) => {
    if (!token) return false;

    const jwt = parseJWT(token.accessToken);
    const now = Math.ceil(Date.now() / 1000);
    return jwt.exp > now;
  };

  const lockAccount = useCallback(async (username, cause) => {
    try {
      const ipAddress = await getIp();
      const data = { ipAddress, username, cause };
      const { status } = await apiClient.post(Urls.suspend, data, { headers });
      return status === 200;
    } catch ({ response }) {
      const { status, statusText, data } = response;
      console.log(status, statusText, data?.detail);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setStorageItem,
        storage,
        auth,
        isRefreshing,
        isAdmin: auth?.rank === 3,
        isAuthenticated,
        setAuth,
        login,
        logout,
        lockAccount,
        parseJWT,
        verifyRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
