import { Divider, Layout } from "../../components";
import { useAleiirt } from "../../store/hooks";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Footer = () => {
  return (
    <div className="footer">
      <span>@2024 Envision Healthcare</span>
      <Divider />
      <span>eiir v1.1.6</span>
    </div>
  );
};

const Home = () => {
  const { repoAlert } = useAleiirt();

  return (
    <div id="home" className="home">
      {repoAlert}
      <Sidebar />
      <Topbar />
      <section className="outlet-section">
        <Layout />
        <Footer />
      </section>
    </div>
  );
};

export default Home;
