import { useCallback, useState } from "react";
import { BsCloudDownload as ServerIcon } from "react-icons/bs";
import { ServerMenu, ServerProgress } from ".";
import { BadgeIndicator, Indicator, Ping } from "../../components";
import { useEiirQueryClient } from "../../repo-query";
import { useCommon } from "../../store/hooks";

function getIndicator(status) {
  return status === "A" ? (
    <BadgeIndicator color="ok" label="Active" />
  ) : status === "R" ? (
    <BadgeIndicator color="basic" label="Ready" />
  ) : status === "S" ? (
    <BadgeIndicator color="progress" label="Staging" />
  ) : (
    <Indicator color="danger" />
  );
}

export const ServerManager = ({ server, openDialog }) => {
  const [inProgress, setInProgress] = useState(false);
  const [dataImport, setDataImport] = useState(null);
  const { loadCommon, setServerStats } = useCommon();
  const eiirQClient = useEiirQueryClient();

  const onStart = useCallback(() => setInProgress(true), []);

  const onStop = useCallback(() => {
    setInProgress(false);
    setDataImport(null);
  }, []);

  const handleGroupStaged = useCallback(
    (server) => {
      setDataImport(server.dataImport[0]);
      onStart();
    },
    [onStart]
  );

  const updateState = useCallback((data) => {
    eiirQClient.setQueryData("servers", (oldState) => {
      const newState = oldState.map((el) =>
        el.serverId === data.serverId
          ? {
              ...el,
              status: data.status,
              channelCount: data.channelCount,
              dataImport: data.dataImport,
            }
          : el
      );

      setServerStats(newState);
      return newState;
    });
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    async (server) => {
      updateState(server);
      loadCommon(true);
    },
    [loadCommon, updateState]
  );

  let indicator, progress;
  if (inProgress && dataImport) {
    indicator = <Ping />;
    progress = (
      <ServerProgress
        serverId={server.serverId}
        staging={dataImport}
        {...{ inProgress, onStart, onStop, onSuccess }}
      />
    );
  } else {
    indicator = getIndicator(server.status);
    progress = (
      <ServerMenu
        modalConfig={{ server, onClose: loadCommon }}
        onStaged={handleGroupStaged}
        onOpenDialog={openDialog}
      />
    );
  }

  return (
    <li key={server.serverId}>
      <div className="smanager">
        <div className="indicatr">{indicator}</div>
        <div className="logo">
          <div className="icon">
            <ServerIcon size={36} color="#FF6F00" />
          </div>
        </div>
        <div className="server">
          <p className="t15-semi-tt">{server.name}</p>
          <p className="sm-text">
            {server.hostname}:{server.port}
          </p>
          <p className="text-sm">{server.dilp}</p>
        </div>
        <div className="details">
          <div>
            <p>{server.ipaddress}</p>
            <span>ip address</span>
          </div>
          <div>
            <p>{server.channelCount?.toLocaleString()}</p>
            <span>channels</span>
          </div>
          <div>
            <p>{server.status === "R" ? "---" : server.lastSynced}</p>
            <span>last synced</span>
          </div>
        </div>
        {progress}
      </div>
    </li>
  );
};
