import { useCallback } from "react";
import { ImUserPlus as NewIcon } from "react-icons/im";
import { NewUserForm } from ".";
import { Card, IconButton, RepoTooltip } from "../../components";
import { useDrawer } from "../../store/hooks";

export const AddUserForm = ({ count, onSuccess }) => {
  const { drawer, openDialog, closeWindow } = useDrawer("userportal");

  const handleAddUserClick = useCallback(() => {
    openDialog({
      title: "Create New User",
      element: NewUserForm,
      onClose: closeWindow,
      onSuccess,
    });
  }, [closeWindow, openDialog, onSuccess]);

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 24,
      }}
    >
      <section className="flex-ij space-x-3 px-2">
        <div className="space-x-1">
          <span className="text-[15px] font-semibold text-gray-800">Users</span>
          <span>( {count.toLocaleString()} )</span>
        </div>

        <span className="user" onClick={handleAddUserClick}>
          <RepoTooltip position="right" label="Add user">
            <IconButton icon={<NewIcon size={20} />} />
          </RepoTooltip>
        </span>
      </section>

      {drawer}
    </Card>
  );
};
