import { RxCheck as CheckIcon } from "react-icons/rx";

const Checkbox = ({ id, label, defaultValue, checked, sx, ...props }) => {
  return (
    <div className="checkbox" style={sx}>
      <label htmlFor={props.id}>
        <input
          name={`chk-${id}`}
          type="checkbox"
          value=""
          checked={defaultValue || checked || false}
          {...props}
        />
        <CheckIcon className="check-icon" />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
