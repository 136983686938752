const region =
  "w-full grid border-none overflow-hidden transition-all duration-300 ease-in-out";

const ExpandedArea = ({ id, children, expanded }) => {
  return (
    // By using Tailwind’s arbitrary values,
    // we applied the grid-rows-[1fr] and grid-rows-[0fr] classes
    // to control the height of the HTML element
    // based on the current state of the expanded variable.

    <div
      id={id}
      role="region"
      className={`${region} ${
        expanded
          ? "grid-rows-[1fr] opacity-100 py-2"
          : "grid-rows-[0fr] opacity-0"
      }`.trimEnd()}
    >
      <section className={"overflow-hidden"}>{children}</section>
    </div>
  );
};

export default ExpandedArea;
