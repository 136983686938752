import { useEffect, useRef } from "react";
import { useForceUpdate } from "../../store/hooks";
import { createCollegue } from "./createCollegue";
import { MediatorClient } from "./MediatorClient";

export const useMediator = (config) => {
  const { forceUpdate } = useForceUpdate();
  const mediator = useRef(new MediatorClient(forceUpdate));

  useEffect(() => {
    const entries = Object.entries(config);
    if (!config || entries.length === 0) return;

    const _mediator = mediator.current;

    for (let [name, props] of entries) {
      const collegue = createCollegue(_mediator, name, props);
      if (!_mediator.register(collegue)) break;
    }

    return () => _mediator.deregister();
  }, [config]);

  return mediator.current;
};

export const FilterPanel = ({ hidden, children }) => {
  const className = hidden
    ? "hidden"
    : "flex flex-col justify-start items-start w-full";
  return (
    <div className={className}>
      <section className="flex items-center space-x-2 mr-16">
        {children}
      </section>
    </div>
  );
};

export { BooleanFilter } from "./BooleanFilter";
export { CheckFilter } from "./CheckFilter";
export { ComboFilter } from "./ComboFilter";
export { OpFilter } from "./OpFilter";
export { withFilter } from "./ProxyFilter";
