import { useCallback, useState } from "react";
import { useTimeout } from ".";
import { Alert } from "../../components";

const defaultDelay = 6000;

const useAlert = (id, footer = false) => {
  const [{ display, delay, danger }, setAlert] = useState({ danger: false });

  const close = useCallback(() => setAlert({ danger }), [danger]);

  const setAlertMessage = useCallback((message) => {
    setAlert({ display: message, delay: defaultDelay, danger: false });
  }, []);

  const setErrorMessage = useCallback((error) => {
    setAlert({ display: error, delay: defaultDelay, danger: true });
  }, []);

  const repoAlert = (
    <Alert
      id={id}
      footer={footer}
      danger={danger}
      message={display}
      callback={close}
    />
  );

  useTimeout(close, delay);

  return { repoAlert, setAlertMessage, setErrorMessage, close };
};

export default useAlert;
