import { Fragment } from "react";
import Skeleton from "./Skeleton";
import { LoadingOverlay } from "./Loaders";
import { useSortableData } from "../store/hooks";

function ColSpan({ key, className, size, children }) {
  return (
    <tr className={className} key={key}>
      <td className="p-3" colSpan={size}>
        {children}
      </td>
    </tr>
  );
}

const TableRow = ({ rowId, config, record, loose }) => {
  const cells = config.map((column, i) => {
    const currClass = [column.align, loose ? "py-[7px]" : "py-0"].join(" ");
    return (
      <td key={i} className={currClass} style={{ width: column.width }}>
        {column.render(record, rowId)}
      </td>
    );
  });

  return <tr className="trow">{cells}</tr>;
};

const Table = ({
  sx,
  columns,
  verticalOnly = true,
  dataSource,
  label,
  loading,
  ...props
}) => {
  const { dataSorted, headerConfig } = useSortableData(dataSource, columns);

  const headers = headerConfig.map((column) => (
    <Fragment key={column.label || column.key}>{column.header()}</Fragment>
  ));

  const tableRows =
    dataSorted && dataSorted.length > 0 ? (
      dataSorted?.map((row, i) => (
        <TableRow
          rowId={i}
          key={`rowid-${i}`}
          record={row}
          config={columns}
          {...props}
        />
      ))
    ) : (
      <ColSpan size={columns.length} className="trow">
        <span className="norow">{label || "No matching records found."}</span>
      </ColSpan>
    );

  return (
    <LoadingOverlay active={loading} spinner text="Loading...">
      <div
        id="repo-scroll"
        className={`table-wrapper${verticalOnly ? " vertical" : ""}`}
      >
        <table className="table-repo sticky">
          <thead className={columns.dark && "thead-dark"}>
            <tr key="header">{headers}</tr>
          </thead>
          <tbody>
            {loading ? (
              <ColSpan size={headers.length}>
                <Skeleton list={{ rows: 6, cols: 4 }} />
              </ColSpan>
            ) : (
              tableRows
            )}
          </tbody>
        </table>
      </div>
    </LoadingOverlay>
  );
};

export default Table;
