import {
  BooleanFilter,
  CheckFilter,
  ComboFilter,
  OpFilter,
  withFilter,
} from ".";
import { withCustom } from "./ProxyFilter";

export function createCollegue(mediator, name, config) {
  const { type, icon, ...props } = config;
  let collegue;

  const handleUpdate = (updater) => mediator.notify(updater);

  if (type === "custom") {
    const { options, ...rest } = props;
    const { element: CustomFilter, ...other } = options;
    collegue = {
      id: name,
      createElement: () => {
        const CustomComponent = withCustom(CustomFilter, other, rest);
        return <CustomComponent key={name} onNotify={handleUpdate} />;
      },
    };
  } else {
    const WrappedFilter =
      type === "operator"
        ? OpFilter
        : type === "select"
        ? ComboFilter
        : type === "check"
        ? CheckFilter
        : BooleanFilter;

    collegue = {
      id: name,
      createElement: () => {
        const ProxyComponent = withFilter(WrappedFilter, props);
        return (
          <ProxyComponent
            key={name}
            icon={icon}
            name={`${name}-filter`}
            onNotify={handleUpdate}
          />
        );
      },
    };
  }

  return collegue;
}
