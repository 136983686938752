import { useCallback, useState } from "react";
import {
  FloatingInput,
  Panel,
  PanelBody,
  ToggleSwitch,
} from "../../components";
import { phone as phoneRule } from "../../form/validation-rules";
import { useEiirMutation } from "../../repo-query";
import { useAxiosPrivate, useTimeout, useToast } from "../../store/hooks";

export const ShowProfile = ({ isSelf, account, onUpdate }) => {
  const axiosPrivate = useAxiosPrivate();
  const { warn, clear, element: toastEl } = useToast();
  const [updates, setUpdates] = useState({
    active: account.status === "A",
    locked: account.accountStatus !== "Unlocked",
    phone: account.phone,
  });

  const [delay, setDelay] = useState(0);
  const { publicId } = account;

  const mutator = useEiirMutation({
    mutationKey: ["users", { publicId, type: "phone" }],
    mutationFn: async (phone) =>
      await axiosPrivate.patch(`/users/${publicId}/phone`, { phone }),
    onSuccess: ({ message }) => onUpdate(message),
    onError: (error) => warn(error.message),
  });

  const handleClear = useCallback(() => {
    clear();
    setDelay(0);
  }, [clear]);

  const handleCancel = useCallback(() => {
    setDelay(2000);
    warn("Phone number update canceled");
  }, [warn]);

  const handleUpdate = useCallback(
    (message) => {
      if (message !== updates.error) {
        setUpdates((curr) => ({ ...curr, error: message }));
        warn(message);
      }
    },
    [updates.error, warn]
  );

  const handleUpdatePhone = useCallback(
    async (phone, isSame) => {
      clear();

      if (isSame) return;

      const rule = phoneRule("Phone Number");
      if (!rule.validate(phone)) handleUpdate(rule.message);
      else mutator.mutate(phone);
    }, // eslint-disable-next-line
    [handleUpdate, clear]
  );

  useTimeout(handleClear, delay);

  const profile = [
    { header: "Public Id", value: publicId },
    { header: "Name Display", value: account.name.display },
    { header: "User Role", value: account.role.name },
    { header: "Access Type", value: "eiir authentication" },
    { header: "Created On", value: account.createdOn },
    { header: "Department", value: account.department },
    { header: "Last Modified", value: account.updatedOn },
  ];

  return (
    <Panel>
      <PanelBody>
        <div>
          <div className="userprofile">
            {profile.map((row, i) => {
              return (
                <div key={row.header}>
                  <span className="header">{row.header}:</span>
                  <span>{row.value}</span>
                </div>
              );
            })}
            <div className="flex-i">
              <span className={`header${isSelf ? " mb-[16px]" : ""}`}>
                Phone:
              </span>
              <div className="flex mt-2 items-start">
                {isSelf ? (
                  <>
                    <FloatingInput
                      helperText="Numbers only, e.g.: 3051234567"
                      defaultValue={updates.phone}
                      width={190}
                      label="Phone"
                      id="user-phone"
                      onChange={() => handleUpdate(null)}
                      onPopOver={handleUpdatePhone}
                      onCancel={handleCancel}
                    />
                    {toastEl}
                  </>
                ) : (
                  <span>{updates.phone}</span>
                )}
              </div>
            </div>
            <div className="flex">
              <span className="header">Active:</span>
              <div className="mt-2">
                <ToggleSwitch
                  disabled
                  id="active-switch"
                  allowed={updates.active}
                />
              </div>
            </div>
            <div className="flex">
              <span className="header">Account Locked:</span>
              <div className="mt-2">
                <ToggleSwitch
                  disabled
                  id="lock-switch"
                  allowed={updates.locked}
                />
              </div>
            </div>
          </div>
        </div>
      </PanelBody>
    </Panel>
  );
};
