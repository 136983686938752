import { useCallback, useMemo, useState } from "react";
import { MdOpenInNew } from "react-icons/md";
import { RiUser6Fill as ProfileIcon } from "react-icons/ri";
import { AddUserForm } from ".";
import {
  IconAvatar,
  IconButton,
  Indicator,
  RepoTooltip,
} from "../../components";
import { Urls } from "../../store/axios";
import { useAuth, useNavigateHistory } from "../../store/hooks";
import UserAccount from "../profile/UserAccount";

export const UserList = ({ onAddUser, onCreateTable, rowCount }) => {
  const [userId, setUserId] = useState(null);
  const { goTo } = useNavigateHistory();
  const { auth } = useAuth();

  const handleView = useCallback(
    (publicId = null) => {
      if (auth.publicId === publicId) goTo(Urls.profile, true, { publicId });
      else setUserId(publicId);
    },
    [auth.publicId, goTo]
  );

  const columns = useMemo(
    () => [
      {
        key: "user-select",
        render: (user) => {
          return (
            <section className="relative z-10">
              <RepoTooltip label="View user profile" position="right">
                <IconButton
                  icon={
                    <MdOpenInNew
                      color="primary"
                      size={22}
                      onClick={() => handleView(user.publicId)}
                    />
                  }
                />
              </RepoTooltip>
            </section>
          );
        },
        align: "align-center",
      },
      {
        label: "Display Name",
        render: (user) => (
          <div className="relative flex space-x-2 items-center py-2">
            <IconAvatar Icon={ProfileIcon} size={20} color="purple" />
            <div>
              <p>{user.display}</p>
              <p className="sm-text">{user.email}</p>
            </div>
          </div>
        ),
        sortValue: (user) => user.display,
      },
      {
        label: "Department",
        render: (user) => (
          <span className="tracking-tight ml-1">{user.department}</span>
        ),
        sortValue: (user) => user.department,
      },
      {
        label: "User Role",
        render: (user) => user.role.name,
        sortValue: (user) => user.role.name,
      },
      {
        label: "Status",
        render: (user) => (
          <div className="flex-j">
            <Indicator color={user.status === "A" ? "ok" : "danger"} />
          </div>
        ),
        align: "text-center",
      },
      {
        label: "Last Active On",
        render: (user) => <span className="ml-1">{user.lastLogin}</span>,
        sortValue: (user) => user.lastLogin,
      },
    ],
    [handleView]
  );

  const isTable = userId === null;

  return (
    <>
      {isTable && <AddUserForm count={rowCount} onSuccess={onAddUser} />}
      {isTable && (
        <div className="users">
          <div className="w-full">{onCreateTable(columns, isTable)}</div>
        </div>
      )}
      {!isTable && <UserAccount userId={userId} onReturn={handleView} />}
    </>
  );
};
