import {
  createStyledLabel,
  edgeType,
  margin,
} from "../../store/hooks/use-dagre";

const position = { x: 0, y: 0 };

export function feedNodes(element) {
  const nodes = [],
    edges = [],
    widths = {},
    parents = {};

  for (let [level, neighbors] of Object.entries(element)) {
    if (level > 0) {
      let xPos = 0;

      // for (let neighbor of neighbors)
      neighbors.forEach((neighbor, i) => {
        const nodeId = `node${level}.${i}`;
        parents[neighbor.node] = nodeId;

        const { label, style, animated } = createStyledLabel(
          neighbor.node,
          neighbor.width
        );

        const newNode = {
          id: nodeId,
          data: { label },
          type: neighbor.isleaf ? "output" : "default",
          style,
          position: { x: xPos, y: level * (margin * 2) },
        };

        const source = parents[neighbor.partof];
        const newEdge = {
          id: `${source}-${nodeId}`,
          source,
          target: nodeId,
          type: edgeType,
          animated,
        };

        nodes.push(newNode);
        edges.push(newEdge);

        xPos += style.width + margin;
        widths[neighbor.partof] =
          (widths[neighbor.partof] || 0) + xPos - margin;
      });
    } else {
      const origin = neighbors[0].node;
      parents[origin] = "node0.0";
      nodes.push({
        id: "node0.0",
        type: "origin",
        data: { label: origin },
        style: {
          width: neighbors[0].width + 40,
        },
        position,
      });
    }
  }

  return [nodes, edges, widths];
}

export const directionGroups = [
  { value: "inbound", label: "Inbound" },
  { value: "outbound", label: "Outbound" },
  { value: "bi-directional", label: "Bi-Directional" },
];
