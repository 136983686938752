import { memo } from "react";
import {
  MdKeyboardArrowLeft as LeftArrow,
  MdKeyboardArrowRight as RightArrow,
} from "react-icons/md";
import usePagination from "../store/hooks/use-pagination";

const DOTS = "...";

const PageLink = ({ active, disabled, element, children, onChange }) => {
  let content = element || children;

  return (
    <li
      className={active ? "active" : disabled ? "disabled" : ""}
      onClick={() => {
        if (!(disabled || active)) onChange(content);
      }}
    >
      {content}
    </li>
  );
};

/**
 * Pagination navigation component that displays enough data
 * to inform users and prevents data overload while presenting
 * just a portion of the data at a time and allowing users to navigate between pages
 *
 * @param display Shows the page list
 * @returns
 */
const PaginationNav = ({
  display,
  title,
  totalCount,
  pageSize,
  page,
  onChange,
}) => {
  const totalPageCount = Math.ceil(totalCount / pageSize); // total page count
  const navigatorRange = usePagination(totalPageCount, page);

  const links = navigatorRange?.map((pg, i) => {
    return pg ? (
      <PageLink
        key={`pg-${pg}`}
        element={pg}
        active={pg === page}
        onChange={onChange}
      />
    ) : (
      <PageLink key={`dots-${i}`} element={DOTS} disabled />
    );
  });

  let count = { from: 0, to: 0 };

  if (totalCount) {
    const lastExpected = Math.ceil(totalCount / pageSize);
    const offset = ((lastExpected < page ? lastExpected : page) - 1) * pageSize;
    const nextCount = offset + pageSize;

    count.from = offset + 1;
    count.to = nextCount < totalCount ? nextCount : totalCount;
  }
  const renderTotal = title ? (
    <>
      <span className="title">{title}</span>{" "}
      <span>( {totalCount?.toLocaleString()} )</span>
    </>
  ) : (
    <div>
      Viewing{" "}
      <b>
        {count.from.toLocaleString()}-{count.to.toLocaleString()}
      </b>
      {" of "} <b>{totalCount.toLocaleString()}</b>
    </div>
  );

  return (
    <div className="pagination">
      <nav aria-label={`${title} Navigation`}>
        <span className="sb-normal">{renderTotal}</span>
        {(totalPageCount > 1 || (display && totalPageCount !== 0)) && (
          <ul>
            {/* left arrow */}
            <PageLink
              key="prev-pg"
              disabled={page === 1}
              element={<LeftArrow fontSize={16} />}
              onChange={() => onChange(page - 1)}
            />
            {links}
            {/*right arrow*/}
            <PageLink
              key="next-pg"
              disabled={page === totalPageCount}
              element={<RightArrow fontSize={16} />}
              onChange={() => onChange(page + 1)}
            />
          </ul>
        )}
      </nav>
    </div>
  );
};

export default memo(PaginationNav);
