import { Children, memo, useCallback, useState } from "react";
import HelperText from "./HelperText";
import { GoChevronDown } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { useClickAway } from "../store/hooks";

export const Option = ({ value, children, onClick }) => (
  <li onClick={() => onClick(value)}>{value || children}</li>
);

const Dropdown = ({
  outline,
  sourceItems,
  children,
  value,
  helperText,
  width,
  onChange,
  disabled,
  showClearButton,
  top,
  id,
}) => {
  const [selection, setSelection] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useClickAway(
    useCallback((outside) => {
      if (outside) setIsOpen(false);
    }, [])
  );

  const toggleDropdown = (e) => {
    if (disabled === undefined || !disabled) setIsOpen((prevOpen) => !prevOpen);
  };

  const handleOptionClick = (option = "") => {
    onChange && onChange(id, option);
    setSelection(option);
    setIsOpen(false);
  };

  const className = `ease_io${isOpen ? " rotate-180" : ""}`;
  const outlineClass = `droparea${outline ? " outline" : ""}`;
  const currLabel = value !== null && value !== undefined ? value : selection;
  const options = sourceItems
    ? sourceItems.map((s, i) => (
        <Option
          key={`${i}-${s.toString().replace(" ", "")}`}
          value={s}
          onClick={handleOptionClick}
        />
      ))
    : Children.count(children) === 1
    ? [children]
    : children;

  return (
    <div
      className={["dropdown", top && "top"].filter(Boolean).join(" ")}
      style={{ width }}
    >
      <button
        type="button"
        disabled={disabled}
        className="outline-none p-0 focus:outline-none ease_io"
        onClick={toggleDropdown}
      >
        <div id={id} ref={divRef} className={outlineClass} style={{ width }}>
          <div>{currLabel}</div>
          <GoChevronDown size={18} className={className} />
        </div>
        {showClearButton && currLabel && (
          <span
            className="border border-slate-500 rounded-full p-0.5 ml-1 mt-2 shadow"
            onClick={() => handleOptionClick()}
          >
            <MdClose size={12} />
          </span>
        )}
      </button>
      {/** Dropdown Container */}
      <ul className={isOpen ? "open" : "close"}>{options}</ul>
      {helperText && <HelperText label={helperText} />}
    </div>
  );
};

export default memo(Dropdown);
