import { useContext } from "react";
import { QueryClientContext } from "../store/contexts/query-context";

export const useEiirQueryClient = () => useContext(QueryClientContext);

export { default as useEiirMutation } from "./useEiirMutation";
export { default as useEiirQuery } from "./useEiirQuery";

export const defaultQueryOptions = {
  mutationKey: ["default"],
  mutationFn: () => {},
  slateTime: 0,
  enabled: false,
};
