import { useEffect, useState } from "react";
import { Divider, ExpandedArea } from ".";
import { BsInfoCircleFill as ToastIcon } from "react-icons/bs";
import { MdError as ErrIcon } from "react-icons/md";

/**
 * Toast notifications provide user with import feedback and alerts.
 * Use this siple toast component with a message,
 * a dismissable close button to show alert messages.
 */
const Toast = ({ id, warning, message }) => {
  const [toast, setToast] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  let Icon;
  const css = ["toast"];

  if (warning) {
    Icon = ErrIcon;
    css.push("warn");
  } else Icon = ToastIcon;

  useEffect(() => {
    let duration = 300;

    if (message === undefined) return;
    if (isExpanded) setIsExpanded(false);
    else duration = 0;

    const timer = setTimeout(() => {
      setToast(message);
      setIsExpanded(message !== null);
    }, duration);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [message]);

  return (
    <ExpandedArea id={id || "toast-expander"} expanded={isExpanded}>
      <div id="toast" role="alert" className={css.filter(Boolean).join(" ")}>
        <span className="p-1">
          <Icon size={20} />
        </span>
        <Divider />
        <div className="message">{toast}</div>
      </div>
    </ExpandedArea>
  );
};

export default Toast;
