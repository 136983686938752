import { delayEvent } from "./use-delay";

/**
 * fn: Retry with exponential backoff.
 * Allows the repeatedly call a specified callback at a defined interval
 * until the callback returns a truthy value
 * or the execution reaches a maximum allowed attempt,
 * indicating a successfuly resolution.
 *
 * @param {*} predicate
 * @param {*} maxAttempts
 * @param {*} baseDelayMs
 * @returns
 */
const useCallWithRetry = ({
  predicate,
  onComplete,
  onFailure,
  onSettled,
  maxAttempts = 4,
  baseDelayMs = 5000,
}) => {
  let attempt = 1;

  // wraps the operation and handles retries
  const execute = (args) => {
    const ms = baseDelayMs * 2 ** attempt++;

    delayEvent(ms).then(() => {
      predicate(args)
        .then(async (server = null) => {
          if (server) {
            onComplete(server);
            onSettled && onSettled();
          } else if (attempt === maxAttempts) {
            onSettled && onSettled();
          } else void execute(args);
        })
        .catch((error) => {
          onFailure && onFailure(error);
          onSettled && onSettled();
        });
    });
  };

  return { withRetry: execute };
};

export default useCallWithRetry;
