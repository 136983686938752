import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import {
  Accordion,
  Button,
  Dropdown,
  LoadSpinner,
  ToggleSwitch,
} from "../../components";
import { TbArrowBackUp as BackIcon } from "react-icons/tb";

export function initToggles(categories) {
  return categories.reduce((catObj, category) => {
    category.permissions.forEach((action) => {
      catObj[action.actionId] = {
        allowed: true,
        level: action.accessLevel.id,
        action: action.action,
        category: action.category,
      };
    });

    return catObj;
  }, {});
}

function hasSameValue(preset, toggles) {
  for (let actionId in preset) {
    if (preset[actionId] !== toggles[actionId].allowed) return false;
  }
  return true;
}

function reduce(toggles) {
  return Object.entries(toggles).reduce((obj, [k, v]) => {
    obj[k] = v.allowed;
    return obj;
  }, {});
}

export const PolicySection = ({ role, isSuperUser, categories, onBack }) => {
  const [viewOnly, setViewOnly] = useState(null);
  const [toggles, setToggles] = useState(initToggles(categories));
  const [selection, setSelection] = useState(isSuperUser ? "View Only" : "All");
  const presetRef = useRef(null);

  useEffect(() => {
    if (presetRef.current && toggles) return;
    presetRef.current = { selection, toggles: reduce(toggles) };
  }, [selection, toggles]);

  const handleBack = () => onBack(toggles);

  const handleViewOnly = (_, value) => {
    const isViewOnly = value === "View Only";

    setViewOnly(isViewOnly);
    setSelection(value);
    setToggles((prevToggles) => {
      const state = {};
      Object.entries(prevToggles).forEach(([k, v]) => {
        state[k] = {
          ...v,
          allowed: isViewOnly ? v.level === 1 : true,
        };
      });

      presetRef.current = { selection: value, toggles: reduce(state) };
      return state;
    });
  };

  const onToggle = useCallback(
    (action, allowed) => {
      if (viewOnly && action.accessLevel > 1) return;

      const prevtoggle = { ...toggles[action.actionId], allowed };
      const state = { ...toggles, [action.actionId]: prevtoggle };
      const samePreset = hasSameValue(presetRef.current.toggles, state);

      setSelection(samePreset ? presetRef.current.selection : "Custom Preset");
      setToggles(state);
    },
    [viewOnly, toggles]
  );

  const items = useMemo(() => {
    return categories.reduce((arr, p) => {
      const controls = [];

      for (let action of p.permissions) {
        controls.push(
          <div key={action.actionId} className="entry">
            <ToggleSwitch
              id={`toggleSwitch-${action.actionId}`}
              allowed={toggles[action.actionId].allowed}
              onSwitch={(allowed) => onToggle(action, allowed)}
            />
            <div className="leading-tight">
              <span className="text-sm">{action.action}</span>
              <div className="text-xs">tag: {action.tag}</div>
            </div>
          </div>
        );
      }

      if (controls.length > 0) {
        arr.push({
          key: p.category,
          label: p.category,
          content: <div className="values">{controls}</div>,
        });
      }

      return arr;
    }, []);
  }, [toggles, categories, onToggle]);

  return (
    <div className={true ? "drawer-section" : "hidden"}>
      {false && (
        <div className="content align-center">
          <LoadSpinner label="Loading User Permissions" />
        </div>
      )}

      <div className="policy">
        <div className="title">
          <p>Select the necessary actions to assign for</p>
          <p>
            Role Selected:{" "}
            <span className="role">
              {role} ({selection})
            </span>
          </p>
        </div>

        <Dropdown
          id="roleselect"
          helperText="Role-based policies presest"
          value={selection}
          sourceItems={["All", "View Only"]}
          disabled={isSuperUser}
          onChange={handleViewOnly}
        />

        <Accordion items={items.filter(Boolean)} sx={{ marginTop: 20 }} />

        <div className="back">
          <Button outline onClick={handleBack}>
            <div className="icon">
              <BackIcon size={18} />
              <span>Back to Roles</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
