import { useMemo, useState } from "react";
import { CgArrowsExchangeAltV as ExFilterIcon } from "react-icons/cg";
import { GoGitMerge as ServiceIcon } from "react-icons/go";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdStream as SlIcon } from "react-icons/md";
import {
  IconButton,
  Panel,
  PanelHeader,
  StackedAvatar,
} from "../../components";
import { useCommon, useTable } from "../../store/hooks";
import { ServiceCharts } from "./ServiceCharts";
import { ServiceSummary } from "./ServiceSummary";

const Interfaces = () => {
  const [service, setService] = useState(null);
  const { feeds, exchangeTypes } = useCommon();

  const options = useMemo(
    () => ({
      facility: {
        type: "operator",
        field: "term",
        id: "servicename-fltr",
        label: "Facility Name",
      },
      serviceline: {
        type: "select",
        field: "service",
        id: "sl-fltr",
        label: "Service Line",
        icon: SlIcon,
        options: {
          title: "Filter by service line",
          subtitle: "Find location that has a specific line of service.",
          sources: Object.values(feeds || {}).map(
            ({ serviceline }) => serviceline
          ),
        },
      },
      exchange: {
        type: "select",
        field: "exchange",
        id: "ex-flter",
        label: "Data Exchange",
        icon: ExFilterIcon,
        options: {
          title: "Filter by exchange type",
          subtitle: "Find a facility that has a specific data exchange type.",
          sources: Object.values(exchangeTypes).map(({ type }) => type),
        },
      },
    }),
    [feeds, exchangeTypes]
  );

  const columns = useMemo(
    () => [
      {
        label: "Service Id",
        render: (service) => service.serviceId,
        align: "text-center",
      },
      {
        label: "Location",
        render: (service) => service.location.name,
        sortValue: (service) => service.location.name,
      },
      {
        label: "COCID",
        render: (service) => service.cocid,
      },
      {
        label: "Connect Id",
        render: (service) => service.connectId,
        align: "text-center",
      },
      {
        label: "Data Exchange",
        render: (service) => (
          <div className="leading-tight">
            <span>{service.mirthfeed.exchangeType.name}</span>
          </div>
        ),
      },
      {
        label: "Services",
        render: (service) => (
          <section>
            <StackedAvatar
              labels={service.subservices.map((s) => s.shortname)}
            />
          </section>
        ),
      },
      {
        key: "Action",
        render: (service) => (
          <IconButton
            title="View Interface Data flow"
            icon={<Icon size={18} />}
            sx={{ margin: "8px 10px" }}
            onClick={() => setService(service)}
          />
        ),
        align: "text-center",
      },
    ],
    []
  );

  const tableConfig = { resource: "services", columns, options };
  const { createTable } = useTable(tableConfig);
  const isTable = service === null;

  return (
    <div className="w-full space-y-4">
      <Panel>
        <PanelHeader
          PanelIcon={ServiceIcon}
          title="Mirth Interfaces"
          subtitle="Services"
        />
      </Panel>
      <ServiceCharts />
      {!isTable && (
        <ServiceSummary service={service} onClose={() => setService(null)} />
      )}
      {createTable(columns, isTable)}
    </div>
  );
};

export default Interfaces;
