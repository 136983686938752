import { useCallback, useEffect, useMemo, useRef } from "react";
import { Pagination, Table } from "../../components";
import { FilterPanel, useMediator } from "../../components/eiir-filter";
import { useEiirQuery } from "../../repo-query";
import { handleSearch, Urls } from "../axios";
import { useAxiosPrivate, useOnce } from "../hooks";

const useTable = ({ resource, options = {} }) => {
  const mediator = useMediator(options);
  const filterComps = useRef([]);
  const axiosPrivate = useAxiosPrivate();

  const filters = mediator.getState();

  const { isLoading, data, refetch } = useEiirQuery({
    queryKey: [resource, { ...filters }],
    queryFn: async () =>
      await axiosPrivate.post(Urls.search[resource], filters),
    enabled: false,
    staleTime: 0,
  });

  useOnce(() => {
    if (Object.keys(options).length === 0) return;
    filterComps.current = <FilterPanel>{mediator.render()}</FilterPanel>;
    // eslint-disable-next-line
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [filters]);

  const { results, totalCount } = useMemo(() => handleSearch(data), [data]);

  return {
    refetch,
    totalCount,
    createTable: useCallback(
      (columns, enabled) => {
        return (
          <>
            <FilterPanel hidden={!enabled}>{filterComps.current}</FilterPanel>
            {enabled && (
              <>
                <Table
                  loading={isLoading}
                  dataSource={results}
                  columns={columns}
                />
                <Pagination mediator={mediator} totalCount={totalCount} />
              </>
            )}
          </>
        );
      },
      // eslint-disable-next-line
      [results, isLoading]
    ),
  };
};

export default useTable;
