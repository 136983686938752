import { createContext, useCallback, useReducer, useState } from "react";
import { Urls } from "../axios";
import { useAxiosPrivate } from "../hooks";

const initialBoard = {
  channelCount: 0,
  feedCount: 0,
  serviceCount: 0,
  hospitals: {},
  locationFeeds: {},
  feeds: [],
  servers: [],
  sources: [],
  destinations: [],
  exchangeTypes: [],
};

const CommonContext = createContext({});

function getConnectors(payload) {
  const types = [];
  for (let [, values] of Object.entries(payload)) {
    for (let [connector, value] of Object.entries(values)) {
      types.push({
        connector,
        alias: value.name,
        active: value.active || 0,
        inactive: value.inactive || 0,
      });
    }
  }

  types.sort((a, b) => (a.active > b.active ? 1 : -1));
  return types;
}

function total(arr, key) {
  return arr.reduce((sum, el) => sum + el[key], 0);
}

function reducer(state, { type, payload }) {
  switch (type) {
    case "connector":
      return { ...state, ...payload };
    case "dashboard":
      const { exchangeTypes, feeds, servers, ...reset } = payload;
      return {
        ...state,
        ...reset,
        exchangeTypes,
        feeds,
        servers,
        feedCount: total(exchangeTypes, "value"),
        serviceCount: total(feeds, "total"),
        channelCount: total(servers, "channelCount"),
      };
    case "server":
      return {
        ...state,
        servers: payload,
        channelCount: total(payload, "channelCount"),
      };
    case "reset":
      return initialBoard;
    default:
      return state;
  }
}

export const CommonProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [established, setEstablished] = useState(false);
  const [commons, dispatch] = useReducer(reducer, initialBoard);
  const axiosPrivate = useAxiosPrivate();

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await axiosPrivate(Urls.reports.notifications);
      setNotifications(data);
    } catch ({ code, response }) {
      console.error(response);
    }
    // eslint-disable-next-line
  }, []);

  const fetchConnectorsStats = useCallback(
    async () => {
      const { data } = await axiosPrivate(Urls.stats);
      dispatch({
        type: "connector",
        payload: {
          sources: getConnectors(data.sources),
          destinations: getConnectors(data.dests),
        },
      });
    },
    // eslint-disable-next-line
    [established]
  );

  const fetchDashboard = useCallback(async () => {
    const { data } = await axiosPrivate(Urls.dashboard);
    dispatch({
      type: "dashboard",
      payload: {
        hospitals: data.hospital || {},
        exchangeTypes: data.exchange || [],
        feeds: data.serviceline || [],
        locationFeeds: data.locationFeeds || {},
        servers: data.servers || [],
      },
    });
    // eslint-disable-next-line
  }, []);

  const loadCommon = useCallback(
    (statsOnly = false) => {
      fetchConnectorsStats().then(() => {
        if (!statsOnly) {
          fetchDashboard();
          fetchNotifications();
        }
      });
    },
    [fetchDashboard, fetchConnectorsStats, fetchNotifications]
  );

  const initialize = useCallback(() => {
    setEstablished(true);
    loadCommon();
  }, [loadCommon]);

  const clearCommon = useCallback(() => {
    setNotifications([]);
    setEstablished(false);
    dispatch({ type: "reset" });
  }, []);

  const removeNotification = (noteId) => {
    setNotifications((curr) => {
      return noteId === "all" ? [] : curr.filter((n) => n.id !== noteId);
    });
  };

  return (
    <CommonContext.Provider
      value={{
        ...commons,
        established,
        notifications,
        initialize,
        clearCommon,
        loadCommon,
        removeNotification,
        setServerStats: (payload) => dispatch({ type: "server", payload }),
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
