import { useMemo } from "react";
import { PiUsersDuotone as UserIcon } from "react-icons/pi";
import { UserList, UserSearch } from ".";
import "../../assets/css/users.main.css";
import { TabContent, Tabs } from "../../components";
import { useTable } from "../../store/hooks";
import { EiirPage } from "../EiirPage";

const Users = () => {
  const options = useMemo(
    () => ({
      user: {
        type: "custom",
        id: "user-filter",
        field: "term",
        options: {
          element: UserSearch,
        },
      },
    }),
    []
  );

  const { totalCount, createTable, refetch } = useTable({
    resource: "users",
    options,
  });

  return (
    <EiirPage icon={UserIcon} title="User Management" subtitle="Accounts">
      <Tabs>
        <TabContent label="Users">
          <UserList
            rowCount={totalCount}
            onCreateTable={createTable}
            onAddUser={refetch}
          />
        </TabContent>
      </Tabs>
    </EiirPage>
  );
};

export default Users;
